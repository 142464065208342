// blacks, whites, and greys
const BLACK = {
    PERCENT_20: '#cccccc',
    PERCENT_73: '#444444',
    PERCENT_87: '#222222',
};
const WHITE = '#ffffff';

// greens
const BRIGHT_GREEN = '#44ff44';

// oranges and yellows
const DARK_TANGERINE = '#ffaa22';
const GOLDEN_HANDSHAKE = '#ffcc44';
const YELLOW_ORANGE = '#ffaa44';

export const RendererOptions = {
    viewOptions: {
        minZoom: 0.0075,
        maxZoom: 8.0,
    },
    edgeHandleOptions: {
        screenSpace: true,
        radius: 4.0,
        segments: 12,
        fillColor: BLACK.PERCENT_20,
        strokeWeight: 1.0,
        strokeColor: GOLDEN_HANDSHAKE,
    },
    vertexHandleOptions: {
        screenSpace: true,
        radius: 4.0,
        segments: 12,
        fillColor: WHITE,
        strokeWeight: 1.0,
        strokeColor: GOLDEN_HANDSHAKE,
    },
    greenOutlineHandleOptions: {
        screenSpace: true,
        radius: 4.0,
        segments: 12,
        fillColor: WHITE,
        strokeWeight: 1.0,
        strokeColor: BRIGHT_GREEN,
    },
    hoverZoneOptions: {
        screenSpace: true,
        width: 24.0,
        opacity: 0,
    },
    edgeLabelOptions: {
        textSize: 9,
        textSegments: 4,
        textFG: WHITE,
        textBG: BLACK.PERCENT_87,
        bgPadding: 1.0,
        offset: 8.0,
        textureFontScaling: 0.36,
    },
    surfaceLabelOptions: {
        textSize: 11,
        textSegments: 4,
        textFG: WHITE,
        textBG: BLACK.PERCENT_73,
        bgPadding: 7.0,
        offset: 8.0,
        textureFontScaling: 0.54,
    },
    createPathOptions: {
        cursorRadius: 4.0,
        cursorSegments: 12,
        cursorColor: BRIGHT_GREEN,
        openPathColor: YELLOW_ORANGE,
        closePathColor: BRIGHT_GREEN,
        pathWeight: 2.0,
        pathOpacity: 1.0,
    },
    moduleControlOptions: {
        moduleOutlineColor: DARK_TANGERINE,
        selectionOutlineColor: BRIGHT_GREEN,
    },
};

export default RendererOptions;
